import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { PostOrdersOrderIdGiveMutationResponse, PostOrdersOrderIdGivePathParams, PostOrdersOrderIdGive400 } from '../entities/PostOrdersOrderIdGive'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postOrdersOrderIdGiveMutationKey = () => [{ url: '/orders/{order_id}/give' }] as const

export type PostOrdersOrderIdGiveMutationKey = ReturnType<typeof postOrdersOrderIdGiveMutationKey>

/**
 * @description Ручка "Передал посылку курьеру"
 * @summary Ручка "Передал посылку курьеру"
 * {@link /orders/:order_id/give}
 */
async function postOrdersOrderIdGive(order_id: PostOrdersOrderIdGivePathParams['order_id'], config: Partial<RequestConfig> = {}) {
  const res = await client<PostOrdersOrderIdGiveMutationResponse, ResponseErrorConfig<PostOrdersOrderIdGive400>, unknown>({
    method: 'POST',
    url: `/orders/${order_id}/give`,
    ...config,
  })
  return res.data
}

/**
 * @description Ручка "Передал посылку курьеру"
 * @summary Ручка "Передал посылку курьеру"
 * {@link /orders/:order_id/give}
 */
export function usePostOrdersOrderIdGive(
  options: {
    mutation?: UseMutationOptions<
      PostOrdersOrderIdGiveMutationResponse,
      ResponseErrorConfig<PostOrdersOrderIdGive400>,
      { order_id: PostOrdersOrderIdGivePathParams['order_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postOrdersOrderIdGiveMutationKey()

  return useMutation<
    PostOrdersOrderIdGiveMutationResponse,
    ResponseErrorConfig<PostOrdersOrderIdGive400>,
    { order_id: PostOrdersOrderIdGivePathParams['order_id'] }
  >({
    mutationFn: async ({ order_id }) => {
      return postOrdersOrderIdGive(order_id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
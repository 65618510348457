import React, { FC } from 'react'
import { ServiceOrder } from '@dostavkee/contracts-console/entities'
import { Alert, Button, IconCourier, IconInfoFilled } from '@dostavkee/web-ui'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'

import { useOrderGiveParcel } from '../../api/mutations/use-order-give-parcel'
import styles from './order-alert.module.scss'

export interface OrderAlertCourierArrivedProps {
    order: ServiceOrder
    fullWidth?: boolean
}

export const OrderAlertCourierArrived: FC<OrderAlertCourierArrivedProps> = ({
    order,
    fullWidth,
}) => {
    const { mutate, isPending } = useOrderGiveParcel({ order })

    return (
        <Alert
            key={order.id}
            icon={<IconInfoFilled size={24} />}
            variant='info'
            className={clsx([
                styles['order-alert'],
                {
                    [styles['order-alert--full-width']]: fullWidth,
                },
            ])}
        >
            <div className={styles['order-alert__content']}>
                <Link
                    params={{ public_id: order.public_id.toString() }}
                    to='/dostavkee/orders/$public_id'
                >
                    Заказ №{order.public_id}
                </Link>{' '}
                <div>Курьер на месте, передайте посылку</div>
            </div>
            <div className={styles['order-alert__controls']}>
                <Button
                    color='white'
                    isLoading={isPending}
                    prefixEl={<IconCourier size={16} />}
                    size='xs'
                    variant='secondary'
                    onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        mutate({ order_id: order.id })
                    }}
                >
                    Передал посылку
                </Button>
            </div>
        </Alert>
    )
}

import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  GetUsersProfileCorpTransactionsQueryResponse,
  GetUsersProfileCorpTransactionsQueryParams,
  GetUsersProfileCorpTransactions400,
} from '../entities/GetUsersProfileCorpTransactions'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUsersProfileCorpTransactionsQueryKey = (params?: GetUsersProfileCorpTransactionsQueryParams) =>
  [{ url: '/users/profile/corp-transactions' }, ...(params ? [params] : [])] as const

export type GetUsersProfileCorpTransactionsQueryKey = ReturnType<typeof getUsersProfileCorpTransactionsQueryKey>

/**
 * @description Получение истории транзакций corp
 * @summary Получение истории транзакций corp
 * {@link /users/profile/corp-transactions}
 */
async function getUsersProfileCorpTransactions(params?: GetUsersProfileCorpTransactionsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersProfileCorpTransactionsQueryResponse, ResponseErrorConfig<GetUsersProfileCorpTransactions400>, unknown>({
    method: 'GET',
    url: `/users/profile/corp-transactions`,
    params,
    ...config,
  })
  return res.data
}

export function getUsersProfileCorpTransactionsQueryOptions(params?: GetUsersProfileCorpTransactionsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getUsersProfileCorpTransactionsQueryKey(params)
  return queryOptions<
    GetUsersProfileCorpTransactionsQueryResponse,
    ResponseErrorConfig<GetUsersProfileCorpTransactions400>,
    GetUsersProfileCorpTransactionsQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUsersProfileCorpTransactions(params, config)
    },
  })
}

/**
 * @description Получение истории транзакций corp
 * @summary Получение истории транзакций corp
 * {@link /users/profile/corp-transactions}
 */
export function useGetUsersProfileCorpTransactions<
  TData = GetUsersProfileCorpTransactionsQueryResponse,
  TQueryData = GetUsersProfileCorpTransactionsQueryResponse,
  TQueryKey extends QueryKey = GetUsersProfileCorpTransactionsQueryKey,
>(
  params?: GetUsersProfileCorpTransactionsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<GetUsersProfileCorpTransactionsQueryResponse, ResponseErrorConfig<GetUsersProfileCorpTransactions400>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUsersProfileCorpTransactionsQueryKey(params)

  const query = useQuery({
    ...(getUsersProfileCorpTransactionsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetUsersProfileCorpTransactions400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}
import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { GetUsersLogoutQueryResponse, GetUsersLogout400 } from '../entities/GetUsersLogout'

export function getGetUsersLogoutUrl() {
  return `/users/logout` as const
}

/**
 * @description User logout
 * @summary Logout
 * {@link /users/logout}
 */
export async function getUsersLogout(config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersLogoutQueryResponse, ResponseErrorConfig<GetUsersLogout400>, unknown>({
    method: 'GET',
    url: getGetUsersLogoutUrl().toString(),
    ...config,
  })
  return res.data
}
import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  PatchOrdersOrderIdArchiveMutationResponse,
  PatchOrdersOrderIdArchivePathParams,
  PatchOrdersOrderIdArchive400,
} from '../entities/PatchOrdersOrderIdArchive'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const patchOrdersOrderIdArchiveMutationKey = () => [{ url: '/orders/{order_id}/archive' }] as const

export type PatchOrdersOrderIdArchiveMutationKey = ReturnType<typeof patchOrdersOrderIdArchiveMutationKey>

/**
 * @description Архивирование заказа
 * @summary Архивирование
 * {@link /orders/:order_id/archive}
 */
async function patchOrdersOrderIdArchive(order_id: PatchOrdersOrderIdArchivePathParams['order_id'], config: Partial<RequestConfig> = {}) {
  const res = await client<PatchOrdersOrderIdArchiveMutationResponse, ResponseErrorConfig<PatchOrdersOrderIdArchive400>, unknown>({
    method: 'PATCH',
    url: `/orders/${order_id}/archive`,
    ...config,
  })
  return res.data
}

/**
 * @description Архивирование заказа
 * @summary Архивирование
 * {@link /orders/:order_id/archive}
 */
export function usePatchOrdersOrderIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PatchOrdersOrderIdArchiveMutationResponse,
      ResponseErrorConfig<PatchOrdersOrderIdArchive400>,
      { order_id: PatchOrdersOrderIdArchivePathParams['order_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? patchOrdersOrderIdArchiveMutationKey()

  return useMutation<
    PatchOrdersOrderIdArchiveMutationResponse,
    ResponseErrorConfig<PatchOrdersOrderIdArchive400>,
    { order_id: PatchOrdersOrderIdArchivePathParams['order_id'] }
  >({
    mutationFn: async ({ order_id }) => {
      return patchOrdersOrderIdArchive(order_id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
import React from 'react'
import { DomainCorpTransactionSourceString } from '@dostavkee/contracts-console/entities'
import { Button, IconArrowDown, TransactionItem } from '@dostavkee/web-ui'
import { Link } from '@tanstack/react-router'

import { useTransactionsInfinite } from '@/corpdrivee/entities/balance'

import { BalanceTransactionsListLoader } from './balance-transactions-list.loader'
import styles from './balance-transactions-list.module.scss'

const TRANSACTION_MESSAGE = new Map([
    [DomainCorpTransactionSourceString.CorpTransactionSourceStringIncome, 'Пополнение баланса'],
    [DomainCorpTransactionSourceString.CorpTransactionSourceStringCharge, 'Оплата заказа'],
    [DomainCorpTransactionSourceString.CorpTransactionSourceStringOrderRefund, 'Возврат средств'],
])

export const BalanceTransactionsListInvoice = () => {
    const { transactions, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
        useTransactionsInfinite()

    if (isLoading) {
        return <BalanceTransactionsListLoader />
    }

    if (transactions?.length === 0) {
        return (
            <div className={styles['balance-transaction-list__empty']}>
                Здесь будет история транзакций
            </div>
        )
    }

    return (
        <section className={styles['balance-transaction-list']}>
            {transactions?.map((transaction) => {
                return (
                    <TransactionItem
                        key={transaction.id}
                        amount={transaction.amount}
                        commission={transaction.commission_percent}
                        date={transaction.date}
                        message={
                            <>
                                <span>{TRANSACTION_MESSAGE.get(transaction.source)}</span>

                                {transaction.source ===
                                DomainCorpTransactionSourceString.CorpTransactionSourceStringCharge ? (
                                    <>
                                        {' '}
                                        <Link
                                            to={`/corpdrivee/ride-orders/$id`}
                                            params={{
                                                id: transaction.order_public_id!.toString(),
                                            }}
                                        >
                                            №{transaction.order_public_id}
                                        </Link>
                                    </>
                                ) : null}
                            </>
                        }
                        variant={
                            [
                                DomainCorpTransactionSourceString.CorpTransactionSourceStringIncome,
                                DomainCorpTransactionSourceString.CorpTransactionSourceStringOrderRefund,
                            ].includes(transaction.source)
                                ? 'income'
                                : 'payment'
                        }
                    />
                )
            })}

            {hasNextPage && (
                <div className={styles['balance-transaction-list__more']}>
                    <Button
                        isText
                        fullWidth={false}
                        isLoading={isFetchingNextPage}
                        variant='ghost'
                        postfixEl={
                            <IconArrowDown color={'var(--text-and-icon-accent)'} size={24} />
                        }
                        onClick={() => fetchNextPage()}
                    >
                        <a href='#' onClick={(event) => event.preventDefault()}>
                            Показать ещё
                        </a>
                    </Button>
                </div>
            )}
        </section>
    )
}

import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconEdit: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M3.8059 19.0149C3.77279 19.1769 3.78036 19.3446 3.82794 19.5029C3.87552 19.6613 3.96162 19.8053 4.07854 19.9222C4.19545 20.0392 4.33953 20.1253 4.49788 20.1728C4.65623 20.2204 4.82391 20.228 4.9859 20.1949L8.7959 19.4149L4.5859 15.2049L3.8059 19.0149ZM9.7269 16.3949L7.6069 14.2749L16.0919 5.78489H16.0939L18.2149 7.90589L9.7259 16.3959L9.7269 16.3949ZM19.6299 6.49188L17.5099 4.36989C17.324 4.18396 17.1032 4.03658 16.8602 3.93619C16.6172 3.8358 16.3568 3.78438 16.0939 3.78489C15.5819 3.78489 15.0699 3.97989 14.6789 4.36989L4.9219 14.1279L9.8719 19.0779L19.6289 9.31989C20.0038 8.94483 20.2145 8.43621 20.2145 7.90589C20.2145 7.37556 20.0038 6.86694 19.6289 6.49188H19.6299Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}

import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  PostOrdersOrderIdCancelMutationRequest,
  PostOrdersOrderIdCancelMutationResponse,
  PostOrdersOrderIdCancelPathParams,
  PostOrdersOrderIdCancel400,
} from '../entities/PostOrdersOrderIdCancel'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postOrdersOrderIdCancelMutationKey = () => [{ url: '/orders/{order_id}/cancel' }] as const

export type PostOrdersOrderIdCancelMutationKey = ReturnType<typeof postOrdersOrderIdCancelMutationKey>

/**
 * @description Отмена заказа
 * @summary Отмена заказа
 * {@link /orders/:order_id/cancel}
 */
async function postOrdersOrderIdCancel(
  order_id: PostOrdersOrderIdCancelPathParams['order_id'],
  data: PostOrdersOrderIdCancelMutationRequest,
  config: Partial<RequestConfig<PostOrdersOrderIdCancelMutationRequest>> = {},
) {
  const res = await client<PostOrdersOrderIdCancelMutationResponse, ResponseErrorConfig<PostOrdersOrderIdCancel400>, PostOrdersOrderIdCancelMutationRequest>({
    method: 'POST',
    url: `/orders/${order_id}/cancel`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Отмена заказа
 * @summary Отмена заказа
 * {@link /orders/:order_id/cancel}
 */
export function usePostOrdersOrderIdCancel(
  options: {
    mutation?: UseMutationOptions<
      PostOrdersOrderIdCancelMutationResponse,
      ResponseErrorConfig<PostOrdersOrderIdCancel400>,
      { order_id: PostOrdersOrderIdCancelPathParams['order_id']; data: PostOrdersOrderIdCancelMutationRequest }
    >
    client?: Partial<RequestConfig<PostOrdersOrderIdCancelMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postOrdersOrderIdCancelMutationKey()

  return useMutation<
    PostOrdersOrderIdCancelMutationResponse,
    ResponseErrorConfig<PostOrdersOrderIdCancel400>,
    { order_id: PostOrdersOrderIdCancelPathParams['order_id']; data: PostOrdersOrderIdCancelMutationRequest }
  >({
    mutationFn: async ({ order_id, data }) => {
      return postOrdersOrderIdCancel(order_id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  PostUsersForgotPasswordVerifyMutationRequest,
  PostUsersForgotPasswordVerifyMutationResponse,
  PostUsersForgotPasswordVerify400,
} from '../entities/PostUsersForgotPasswordVerify'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postUsersForgotPasswordVerifyMutationKey = () => [{ url: '/users/forgot-password/verify' }] as const

export type PostUsersForgotPasswordVerifyMutationKey = ReturnType<typeof postUsersForgotPasswordVerifyMutationKey>

/**
 * @description Подтверждение восстановления пароля
 * @summary Подтверждение
 * {@link /users/forgot-password/verify}
 */
async function postUsersForgotPasswordVerify(
  data: PostUsersForgotPasswordVerifyMutationRequest,
  config: Partial<RequestConfig<PostUsersForgotPasswordVerifyMutationRequest>> = {},
) {
  const res = await client<
    PostUsersForgotPasswordVerifyMutationResponse,
    ResponseErrorConfig<PostUsersForgotPasswordVerify400>,
    PostUsersForgotPasswordVerifyMutationRequest
  >({ method: 'POST', url: `/users/forgot-password/verify`, data, ...config })
  return res.data
}

/**
 * @description Подтверждение восстановления пароля
 * @summary Подтверждение
 * {@link /users/forgot-password/verify}
 */
export function usePostUsersForgotPasswordVerify(
  options: {
    mutation?: UseMutationOptions<
      PostUsersForgotPasswordVerifyMutationResponse,
      ResponseErrorConfig<PostUsersForgotPasswordVerify400>,
      { data: PostUsersForgotPasswordVerifyMutationRequest }
    >
    client?: Partial<RequestConfig<PostUsersForgotPasswordVerifyMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postUsersForgotPasswordVerifyMutationKey()

  return useMutation<
    PostUsersForgotPasswordVerifyMutationResponse,
    ResponseErrorConfig<PostUsersForgotPasswordVerify400>,
    { data: PostUsersForgotPasswordVerifyMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return postUsersForgotPasswordVerify(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  PostUsersProfileBalanceMutationRequest,
  PostUsersProfileBalanceMutationResponse,
  PostUsersProfileBalance400,
} from '../entities/PostUsersProfileBalance'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postUsersProfileBalanceMutationKey = () => [{ url: '/users/profile/balance' }] as const

export type PostUsersProfileBalanceMutationKey = ReturnType<typeof postUsersProfileBalanceMutationKey>

/**
 * @description Пополнение баланса
 * @summary Пополнение баланса
 * {@link /users/profile/balance}
 */
async function postUsersProfileBalance(
  data: PostUsersProfileBalanceMutationRequest,
  config: Partial<RequestConfig<PostUsersProfileBalanceMutationRequest>> = {},
) {
  const res = await client<PostUsersProfileBalanceMutationResponse, ResponseErrorConfig<PostUsersProfileBalance400>, PostUsersProfileBalanceMutationRequest>({
    method: 'POST',
    url: `/users/profile/balance`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Пополнение баланса
 * @summary Пополнение баланса
 * {@link /users/profile/balance}
 */
export function usePostUsersProfileBalance(
  options: {
    mutation?: UseMutationOptions<
      PostUsersProfileBalanceMutationResponse,
      ResponseErrorConfig<PostUsersProfileBalance400>,
      { data: PostUsersProfileBalanceMutationRequest }
    >
    client?: Partial<RequestConfig<PostUsersProfileBalanceMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postUsersProfileBalanceMutationKey()

  return useMutation<
    PostUsersProfileBalanceMutationResponse,
    ResponseErrorConfig<PostUsersProfileBalance400>,
    { data: PostUsersProfileBalanceMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return postUsersProfileBalance(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
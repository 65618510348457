import React, { FC } from 'react'
import clsx from 'clsx'

import styles from './modal.module.scss'

interface ModalBodyProps {
    children: React.ReactNode
    isFitContent?: boolean
}

export const ModalBody: FC<ModalBodyProps> = ({ children, isFitContent = false }) => {
    return (
        <main
            className={clsx({
                [styles['modal__body']]: true,
                [styles['modal__body--fit-content']]: isFitContent,
            })}
        >
            {children}
        </main>
    )
}

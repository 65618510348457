import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { PostUsersAuthRefreshMutationResponse, PostUsersAuthRefresh400 } from '../entities/PostUsersAuthRefresh'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postUsersAuthRefreshMutationKey = () => [{ url: '/users/auth/refresh' }] as const

export type PostUsersAuthRefreshMutationKey = ReturnType<typeof postUsersAuthRefreshMutationKey>

/**
 * @description User refresh
 * @summary Refresh
 * {@link /users/auth/refresh}
 */
async function postUsersAuthRefresh(config: Partial<RequestConfig> = {}) {
  const res = await client<PostUsersAuthRefreshMutationResponse, ResponseErrorConfig<PostUsersAuthRefresh400>, unknown>({
    method: 'POST',
    url: `/users/auth/refresh`,
    ...config,
  })
  return res.data
}

/**
 * @description User refresh
 * @summary Refresh
 * {@link /users/auth/refresh}
 */
export function usePostUsersAuthRefresh(
  options: {
    mutation?: UseMutationOptions<PostUsersAuthRefreshMutationResponse, ResponseErrorConfig<PostUsersAuthRefresh400>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postUsersAuthRefreshMutationKey()

  return useMutation<PostUsersAuthRefreshMutationResponse, ResponseErrorConfig<PostUsersAuthRefresh400>>({
    mutationFn: async () => {
      return postUsersAuthRefresh(config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
import type { ErrorStruct } from './ErrorStruct'
import type { ServiceGetOrders } from './service/GetOrders'

export enum GetOrdersQueryParamsType {
  'active' = 'active',
  'archive' = 'archive',
}

export type GetOrdersQueryParams = {
  /**
   * @description type enum
   * @type string
   */
  type: GetOrdersQueryParamsType
  /**
   * @description int limit
   * @type integer | undefined
   */
  limit?: number
  /**
   * @description int offset
   * @type integer | undefined
   */
  offset?: number
}

/**
 * @description OK
 */
export type GetOrders200 = ServiceGetOrders

/**
 * @description Bad Request
 */
export type GetOrders400 = ErrorStruct

export type GetOrdersQueryResponse = GetOrders200

export type GetOrdersQuery = {
  Response: GetOrders200
  QueryParams: GetOrdersQueryParams
  Errors: GetOrders400
}
import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconWarningCircleFilled: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 25'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M12 22.9692C17.5228 22.9692 22 18.4921 22 12.9692C22 7.44639 17.5228 2.96924 12 2.96924C6.47715 2.96924 2 7.44639 2 12.9692C2 18.4921 6.47715 22.9692 12 22.9692ZM12 8.09424C11.4477 8.09424 11 8.54195 11 9.09424V13.0942C11 13.6465 11.4477 14.0942 12 14.0942C12.5523 14.0942 13 13.6465 13 13.0942V9.09424C13 8.54195 12.5523 8.09424 12 8.09424ZM12 17.8442C11.3096 17.8442 10.75 17.2846 10.75 16.5942C10.75 15.9039 11.3096 15.3442 12 15.3442C12.6904 15.3442 13.25 15.9039 13.25 16.5942C13.25 17.2846 12.6904 17.8442 12 17.8442Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}

import { ResponseErrorConfig } from '@dostavkee/contracts-console/axios-client'
import {
    GetUsersProfile400,
    GetUsersProfileQueryResponse,
} from '@dostavkee/contracts-console/entities'
import { GetUsersProfileQueryKey, useGetUsersProfile } from '@dostavkee/contracts-console/hooks'
import { QueryObserverOptions } from '@tanstack/react-query'

interface UseProfileProps {
    query?: Partial<
        QueryObserverOptions<
            GetUsersProfileQueryResponse,
            ResponseErrorConfig<GetUsersProfile400>,
            GetUsersProfileQueryResponse,
            GetUsersProfileQueryResponse,
            GetUsersProfileQueryKey
        >
    >
}

export const useProfile = ({ query }: UseProfileProps) => {
    const { data, isLoading, error } = useGetUsersProfile({ query })

    return {
        profile: data,
        isDostavkeeEnabled: Boolean(data?.user.city.enabled),
        isCorpdriveeEnabled: Boolean(data?.user.city.options.is_corp_drivee_enabled),
        isLoading,
        error,
    }
}

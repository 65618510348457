import { createFileRoute } from '@tanstack/react-router'

import { BALANCE_PAGE_TAB } from '@/corpdrivee/pages/balance-page'

export const Route = createFileRoute('/_dashboard/corpdrivee/_sidebar-layout/balance')({
    onEnter: () => {
        document.title = 'CorpDrivee - Баланс'
    },
    validateSearch: (
        search: Record<string, unknown>
    ): {
        tab?: BALANCE_PAGE_TAB
        bind_card_payment_status?: string
    } => {
        return {
            tab: (search?.tab as BALANCE_PAGE_TAB) ?? BALANCE_PAGE_TAB.INVOICE,
            bind_card_payment_status: search?.bind_card_payment_status as string,
        }
    },
})

import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  GetUsersProfilePaymentIdQueryResponse,
  GetUsersProfilePaymentIdPathParams,
  GetUsersProfilePaymentId400,
} from '../entities/GetUsersProfilePaymentPaymentId'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUsersProfilePaymentPaymentIdQueryKey = (payment_id: GetUsersProfilePaymentIdPathParams['payment_id']) =>
  [{ url: '/users/profile/payment/:payment_id', params: { payment_id: payment_id } }] as const

export type GetUsersProfilePaymentPaymentIdQueryKey = ReturnType<typeof getUsersProfilePaymentPaymentIdQueryKey>

/**
 * @description Получение статуса платежа
 * @summary Получение статуса платежа
 * {@link /users/profile/payment/:payment_id}
 */
async function getUsersProfilePaymentPaymentId(payment_id: GetUsersProfilePaymentIdPathParams['payment_id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersProfilePaymentIdQueryResponse, ResponseErrorConfig<GetUsersProfilePaymentId400>, unknown>({
    method: 'GET',
    url: `/users/profile/payment/${payment_id}`,
    ...config,
  })
  return res.data
}

export function getUsersProfilePaymentPaymentIdQueryOptions(payment_id: GetUsersProfilePaymentIdPathParams['payment_id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getUsersProfilePaymentPaymentIdQueryKey(payment_id)
  return queryOptions<
    GetUsersProfilePaymentIdQueryResponse,
    ResponseErrorConfig<GetUsersProfilePaymentId400>,
    GetUsersProfilePaymentIdQueryResponse,
    typeof queryKey
  >({
    enabled: !!payment_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUsersProfilePaymentPaymentId(payment_id, config)
    },
  })
}

/**
 * @description Получение статуса платежа
 * @summary Получение статуса платежа
 * {@link /users/profile/payment/:payment_id}
 */
export function useGetUsersProfilePaymentPaymentId<
  TData = GetUsersProfilePaymentIdQueryResponse,
  TQueryData = GetUsersProfilePaymentIdQueryResponse,
  TQueryKey extends QueryKey = GetUsersProfilePaymentPaymentIdQueryKey,
>(
  payment_id: GetUsersProfilePaymentIdPathParams['payment_id'],
  options: {
    query?: Partial<QueryObserverOptions<GetUsersProfilePaymentIdQueryResponse, ResponseErrorConfig<GetUsersProfilePaymentId400>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUsersProfilePaymentPaymentIdQueryKey(payment_id)

  const query = useQuery({
    ...(getUsersProfilePaymentPaymentIdQueryOptions(payment_id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetUsersProfilePaymentId400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}
import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { GetUsersProfileBalanceQueryResponse, GetUsersProfileBalance400 } from '../entities/GetUsersProfileBalance'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUsersProfileBalanceQueryKey = () => [{ url: '/users/profile/balance' }] as const

export type GetUsersProfileBalanceQueryKey = ReturnType<typeof getUsersProfileBalanceQueryKey>

/**
 * @description Получение баланса пользователя
 * @summary Баланс пользователя
 * {@link /users/profile/balance}
 */
async function getUsersProfileBalance(config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersProfileBalanceQueryResponse, ResponseErrorConfig<GetUsersProfileBalance400>, unknown>({
    method: 'GET',
    url: `/users/profile/balance`,
    ...config,
  })
  return res.data
}

export function getUsersProfileBalanceQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = getUsersProfileBalanceQueryKey()
  return queryOptions<
    GetUsersProfileBalanceQueryResponse,
    ResponseErrorConfig<GetUsersProfileBalance400>,
    GetUsersProfileBalanceQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUsersProfileBalance(config)
    },
  })
}

/**
 * @description Получение баланса пользователя
 * @summary Баланс пользователя
 * {@link /users/profile/balance}
 */
export function useGetUsersProfileBalance<
  TData = GetUsersProfileBalanceQueryResponse,
  TQueryData = GetUsersProfileBalanceQueryResponse,
  TQueryKey extends QueryKey = GetUsersProfileBalanceQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<GetUsersProfileBalanceQueryResponse, ResponseErrorConfig<GetUsersProfileBalance400>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUsersProfileBalanceQueryKey()

  const query = useQuery({
    ...(getUsersProfileBalanceQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetUsersProfileBalance400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}
import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { GetUsersProfileQueryResponse, GetUsersProfile400 } from '../entities/GetUsersProfile'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUsersProfileQueryKey = () => [{ url: '/users/profile' }] as const

export type GetUsersProfileQueryKey = ReturnType<typeof getUsersProfileQueryKey>

/**
 * @description Получение профиля
 * @summary Получение профиля
 * {@link /users/profile}
 */
async function getUsersProfile(config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersProfileQueryResponse, ResponseErrorConfig<GetUsersProfile400>, unknown>({ method: 'GET', url: `/users/profile`, ...config })
  return res.data
}

export function getUsersProfileQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = getUsersProfileQueryKey()
  return queryOptions<GetUsersProfileQueryResponse, ResponseErrorConfig<GetUsersProfile400>, GetUsersProfileQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUsersProfile(config)
    },
  })
}

/**
 * @description Получение профиля
 * @summary Получение профиля
 * {@link /users/profile}
 */
export function useGetUsersProfile<
  TData = GetUsersProfileQueryResponse,
  TQueryData = GetUsersProfileQueryResponse,
  TQueryKey extends QueryKey = GetUsersProfileQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<GetUsersProfileQueryResponse, ResponseErrorConfig<GetUsersProfile400>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUsersProfileQueryKey()

  const query = useQuery({
    ...(getUsersProfileQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetUsersProfile400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}
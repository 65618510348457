import { ResponseErrorConfig } from '@dostavkee/contracts-console/axios-client'
import {
    GetOrdersOrderIdCancelReasons400,
    GetOrdersOrderIdCancelReasonsQueryResponse,
    ServiceOrder,
} from '@dostavkee/contracts-console/entities'
import {
    GetOrdersOrderIdCancelReasonsQueryKey,
    useGetOrdersOrderIdCancelReasons,
} from '@dostavkee/contracts-console/hooks'
import { QueryObserverOptions } from '@tanstack/react-query'

interface UseOrderCancelReasonsProps {
    order: ServiceOrder
    query?: QueryObserverOptions<
        GetOrdersOrderIdCancelReasonsQueryResponse,
        ResponseErrorConfig<GetOrdersOrderIdCancelReasons400>,
        GetOrdersOrderIdCancelReasonsQueryResponse,
        GetOrdersOrderIdCancelReasonsQueryResponse,
        GetOrdersOrderIdCancelReasonsQueryKey
    >
}

export const useOrderCancelReasons = ({ order, query }: UseOrderCancelReasonsProps) => {
    const { data, isLoading } = useGetOrdersOrderIdCancelReasons(order.id, {
        query,
    })

    return { reasons: data?.reasons, isLoading }
}

import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  PostUsersProfileInvoiceMutationRequest,
  PostUsersProfileInvoiceMutationResponse,
  PostUsersProfileInvoice400,
} from '../entities/PostUsersProfileInvoice'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postUsersProfileInvoiceMutationKey = () => [{ url: '/users/profile/invoice' }] as const

export type PostUsersProfileInvoiceMutationKey = ReturnType<typeof postUsersProfileInvoiceMutationKey>

/**
 * @description Генерация счета
 * @summary Генерация счета
 * {@link /users/profile/invoice}
 */
async function postUsersProfileInvoice(
  data?: PostUsersProfileInvoiceMutationRequest,
  config: Partial<RequestConfig<PostUsersProfileInvoiceMutationRequest>> = {},
) {
  const res = await client<PostUsersProfileInvoiceMutationResponse, ResponseErrorConfig<PostUsersProfileInvoice400>, PostUsersProfileInvoiceMutationRequest>({
    method: 'POST',
    url: `/users/profile/invoice`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Генерация счета
 * @summary Генерация счета
 * {@link /users/profile/invoice}
 */
export function usePostUsersProfileInvoice(
  options: {
    mutation?: UseMutationOptions<
      PostUsersProfileInvoiceMutationResponse,
      ResponseErrorConfig<PostUsersProfileInvoice400>,
      { data?: PostUsersProfileInvoiceMutationRequest }
    >
    client?: Partial<RequestConfig<PostUsersProfileInvoiceMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postUsersProfileInvoiceMutationKey()

  return useMutation<
    PostUsersProfileInvoiceMutationResponse,
    ResponseErrorConfig<PostUsersProfileInvoice400>,
    { data?: PostUsersProfileInvoiceMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return postUsersProfileInvoice(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
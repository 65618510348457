import React, { useEffect, useRef } from 'react'
import { useOnClickOutside } from '@dostavkee/react-hooks'
import {
    IconChevronDownSmall,
    IconChevronUpSmall,
    IconLogoCorpdrivee,
    IconLogoDostavkee,
} from '@dostavkee/web-ui'
import { Link, useLocation, useRouterState } from '@tanstack/react-router'
import { atom, useAtom } from 'jotai'

import { MODULE_NAMES } from '../../config/module-names'
import { TOUR_NAME } from '../../config/tour-name'
import { useActiveModule, useAuth, useSidebar } from '../../model'
import styles from './module-switcher.module.scss'

const isOpenModuleSwitcherAtom = atom(false)

export const useIsOpenModuleSwitcher = () => useAtom(isOpenModuleSwitcherAtom)

export const ModuleSwitcher = () => {
    const [isOpen, setIsOpen] = useIsOpenModuleSwitcher()
    const { activeModule, setActiveModule } = useActiveModule()
    const { isCorpdriveeEnabled, isDostavkeeEnabled } = useAuth()
    const { setIsSidebarOpen } = useSidebar()

    const hash = useRouterState({ select: (state) => state.location.hash })

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        /**
         * Не закрываем переключатель модулей если мы находимся в режиме тура
         */
        if (!Object.values(TOUR_NAME).includes(hash as TOUR_NAME)) {
            setIsOpen(false)
        }
    })

    const location = useLocation()

    /**
     * Если активный модуль не соответствует текущему пути, то меняем его
     */
    useEffect(() => {
        if (
            activeModule !== MODULE_NAMES.corpdrivee &&
            location.pathname.startsWith('/corpdrivee')
        ) {
            setActiveModule(MODULE_NAMES.corpdrivee)
        }
        if (activeModule !== MODULE_NAMES.dostavkee && location.pathname.startsWith('/dostavkee')) {
            setActiveModule(MODULE_NAMES.dostavkee)
        }
    }, [activeModule, location.pathname, setActiveModule])

    /**
     * Если модули не включены – то не показываем переключатель модулей
     */
    if (!isCorpdriveeEnabled && !isDostavkeeEnabled) {
        return null
    }

    /**
     * Если корпоративные поездки не включено – то не показываем переключатель модулей
     */
    if (!isCorpdriveeEnabled) {
        return null
    }

    return (
        <div className={styles['module-switcher']} id='module-switcher'>
            <a
                className={styles['module-switcher__selected-module']}
                href='#'
                onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setIsOpen(true)
                }}
            >
                <span>
                    {location.pathname.startsWith('/corpdrivee')
                        ? 'Корпоративные поездки'
                        : 'Курьерские доставки'}{' '}
                </span>
                {isOpen ? <IconChevronUpSmall /> : <IconChevronDownSmall />}
            </a>
            {isOpen && (
                <div ref={ref} className={styles['module-switcher__modules-list']}>
                    <Link
                        className={styles['module-switcher__modules-list-item']}
                        id='dostavkee-link'
                        to='/dostavkee'
                        activeProps={{
                            className: styles['module-switcher__modules-list-item--active'],
                        }}
                        onClick={() => {
                            setIsOpen(false)
                            setIsSidebarOpen(false)
                        }}
                    >
                        <IconLogoDostavkee size={28} />
                        <span>Курьерские доставки</span>
                    </Link>
                    <Link
                        className={styles['module-switcher__modules-list-item']}
                        id='corpdrivee-link'
                        to='/corpdrivee'
                        activeProps={{
                            className: styles['module-switcher__modules-list-item--active'],
                        }}
                        onClick={() => {
                            setIsOpen(false)
                            setIsSidebarOpen(false)
                        }}
                    >
                        <IconLogoCorpdrivee size={28} />
                        <span>Корпоративные поездки</span>
                    </Link>
                </div>
            )}
        </div>
    )
}

import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  PostUsersForgotPasswordMutationRequest,
  PostUsersForgotPasswordMutationResponse,
  PostUsersForgotPassword400,
} from '../entities/PostUsersForgotPassword'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postUsersForgotPasswordMutationKey = () => [{ url: '/users/forgot-password' }] as const

export type PostUsersForgotPasswordMutationKey = ReturnType<typeof postUsersForgotPasswordMutationKey>

/**
 * @description Восстановление пароля
 * @summary Восстановление пароля
 * {@link /users/forgot-password}
 */
async function postUsersForgotPassword(
  data: PostUsersForgotPasswordMutationRequest,
  config: Partial<RequestConfig<PostUsersForgotPasswordMutationRequest>> = {},
) {
  const res = await client<PostUsersForgotPasswordMutationResponse, ResponseErrorConfig<PostUsersForgotPassword400>, PostUsersForgotPasswordMutationRequest>({
    method: 'POST',
    url: `/users/forgot-password`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Восстановление пароля
 * @summary Восстановление пароля
 * {@link /users/forgot-password}
 */
export function usePostUsersForgotPassword(
  options: {
    mutation?: UseMutationOptions<
      PostUsersForgotPasswordMutationResponse,
      ResponseErrorConfig<PostUsersForgotPassword400>,
      { data: PostUsersForgotPasswordMutationRequest }
    >
    client?: Partial<RequestConfig<PostUsersForgotPasswordMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postUsersForgotPasswordMutationKey()

  return useMutation<
    PostUsersForgotPasswordMutationResponse,
    ResponseErrorConfig<PostUsersForgotPassword400>,
    { data: PostUsersForgotPasswordMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return postUsersForgotPassword(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
import React from 'react'
import { useRouterState } from '@tanstack/react-router'

import { MODULE_NAMES } from '@/shell/shared/config'

const FAV_SELECTORS = new Map<string, string>([
    ['link[rel="icon"][sizes="96x96"]', 'favicon-96x96.png'],
    ['link[rel="icon"][type="image/svg+xml"]', 'favicon.svg'],
    ['link[rel="shortcut icon"]', 'favicon.ico'],
    ['link[rel="apple-touch-icon"]', 'apple-touch-icon.png'],
    ['link[rel="manifest"]', 'site.webmanifest'],
])

const handleChangeFavicon = (moduleName: string) => {
    if (moduleName === MODULE_NAMES.corpdrivee) {
        for (const [selector, filename] of FAV_SELECTORS) {
            document.querySelector(selector)?.setAttribute('href', `/favicon-corp/${filename}`)
        }
    }

    if (moduleName === MODULE_NAMES.dostavkee) {
        for (const [selector, filename] of FAV_SELECTORS) {
            document.querySelector(selector)?.setAttribute('href', `/favicon/${filename}`)
        }
    }
}

export const ModuleThemeSwitcher = () => {
    const pathname = useRouterState({
        select: (state) => state.location.pathname,
    })

    const isCorpdrivee = pathname.startsWith('/corpdrivee')
    const hasCorpdriveeClass = document.documentElement.classList.contains('corpdrivee')

    React.useEffect(() => {
        if (isCorpdrivee && !hasCorpdriveeClass) {
            document.documentElement.classList.add('corpdrivee')

            handleChangeFavicon(MODULE_NAMES.corpdrivee)
        } else if (!isCorpdrivee && hasCorpdriveeClass) {
            document.documentElement.classList.remove('corpdrivee')

            handleChangeFavicon(MODULE_NAMES.dostavkee)
        }
    }, [hasCorpdriveeClass, isCorpdrivee])

    return null
}

import React, { FC, memo } from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import {
    Alert,
    Button,
    IconAdd,
    IconInfoFilled,
    IconWarningFilled,
    Spinner,
} from '@dostavkee/web-ui'

import { useSidebar } from '@/shell/shared/model'

import styles from './sidebar-balance.module.scss'

interface SidebarBalanceProps {
    disabled?: boolean
    amount?: number
    onClickPaymentButton: () => void
    errorMessage?: string
    warningMessage?: string
}

export const SidebarBalance: FC<SidebarBalanceProps> = memo(
    ({
        disabled,
        amount,
        onClickPaymentButton,
        errorMessage,
        warningMessage,
    }: SidebarBalanceProps) => {
        const { isMobile } = useDeviceDetection()

        const { setIsSidebarOpen } = useSidebar()

        const handleClickPaymentButton = () => {
            onClickPaymentButton()
            setIsSidebarOpen(false)
        }

        return (
            <div className={styles['sidebar-balance-wrapper']}>
                <section className={styles['sidebar-balance']} id='price-card'>
                    <h1>Баланс</h1>
                    <div className={styles['sidebar-balance-controls']}>
                        <div>
                            {typeof amount === 'number' ? moneyFormatter(amount) : <Spinner />}
                        </div>
                        {isMobile ? (
                            <Button
                                disabled={disabled}
                                size='xs'
                                onClick={handleClickPaymentButton}
                            >
                                Пополнить
                            </Button>
                        ) : (
                            <Button
                                isText
                                disabled={disabled}
                                prefixEl={<IconAdd color='white' size={16} />}
                                size='xs'
                                onClick={handleClickPaymentButton}
                            />
                        )}
                    </div>
                </section>
                {errorMessage && (
                    <Alert
                        icon={<IconWarningFilled color='var(--extensions-background-error)' />}
                        size='small'
                        style={{ marginTop: 8 }}
                        variant='error'
                    >
                        {errorMessage}
                    </Alert>
                )}
                {warningMessage && (
                    <Alert
                        icon={<IconInfoFilled />}
                        size='small'
                        style={{ marginTop: 8 }}
                        variant='warning'
                    >
                        {warningMessage}
                    </Alert>
                )}
            </div>
        )
    }
)
SidebarBalance.displayName = 'SidebarBalance'

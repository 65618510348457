import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { GetUsersProfileWebimQueryResponse, GetUsersProfileWebim400 } from '../entities/GetUsersProfileWebim'

export function getGetUsersProfileWebimUrl() {
  return `/users/profile/webim` as const
}

/**
 * @description Получение авторизованного посетителя для Webim
 * @summary Get Webim Visitor
 * {@link /users/profile/webim}
 */
export async function getUsersProfileWebim(config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersProfileWebimQueryResponse, ResponseErrorConfig<GetUsersProfileWebim400>, unknown>({
    method: 'GET',
    url: getGetUsersProfileWebimUrl().toString(),
    ...config,
  })
  return res.data
}
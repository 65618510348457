import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  GetUsersProfileCorpTransactionsQueryResponse,
  GetUsersProfileCorpTransactionsQueryParams,
  GetUsersProfileCorpTransactions400,
} from '../entities/GetUsersProfileCorpTransactions'

export function getGetUsersProfileCorpTransactionsUrl() {
  return `/users/profile/corp-transactions` as const
}

/**
 * @description Получение истории транзакций corp
 * @summary Получение истории транзакций corp
 * {@link /users/profile/corp-transactions}
 */
export async function getUsersProfileCorpTransactions(params?: GetUsersProfileCorpTransactionsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetUsersProfileCorpTransactionsQueryResponse, ResponseErrorConfig<GetUsersProfileCorpTransactions400>, unknown>({
    method: 'GET',
    url: getGetUsersProfileCorpTransactionsUrl().toString(),
    params,
    ...config,
  })
  return res.data
}
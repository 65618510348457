import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { GetOrdersPublicIdQueryResponse, GetOrdersPublicIdPathParams, GetOrdersPublicId400 } from '../entities/GetOrdersPublicId'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getOrdersPublicIdQueryKey = (public_id: GetOrdersPublicIdPathParams['public_id']) =>
  [{ url: '/orders/:public_id', params: { public_id: public_id } }] as const

export type GetOrdersPublicIdQueryKey = ReturnType<typeof getOrdersPublicIdQueryKey>

/**
 * @description Получение заказа
 * @summary Получение заказа
 * {@link /orders/:public_id}
 */
async function getOrdersPublicId(public_id: GetOrdersPublicIdPathParams['public_id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetOrdersPublicIdQueryResponse, ResponseErrorConfig<GetOrdersPublicId400>, unknown>({
    method: 'GET',
    url: `/orders/${public_id}`,
    ...config,
  })
  return res.data
}

export function getOrdersPublicIdQueryOptions(public_id: GetOrdersPublicIdPathParams['public_id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getOrdersPublicIdQueryKey(public_id)
  return queryOptions<GetOrdersPublicIdQueryResponse, ResponseErrorConfig<GetOrdersPublicId400>, GetOrdersPublicIdQueryResponse, typeof queryKey>({
    enabled: !!public_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getOrdersPublicId(public_id, config)
    },
  })
}

/**
 * @description Получение заказа
 * @summary Получение заказа
 * {@link /orders/:public_id}
 */
export function useGetOrdersPublicId<
  TData = GetOrdersPublicIdQueryResponse,
  TQueryData = GetOrdersPublicIdQueryResponse,
  TQueryKey extends QueryKey = GetOrdersPublicIdQueryKey,
>(
  public_id: GetOrdersPublicIdPathParams['public_id'],
  options: {
    query?: Partial<QueryObserverOptions<GetOrdersPublicIdQueryResponse, ResponseErrorConfig<GetOrdersPublicId400>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getOrdersPublicIdQueryKey(public_id)

  const query = useQuery({
    ...(getOrdersPublicIdQueryOptions(public_id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetOrdersPublicId400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}
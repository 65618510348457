import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { PatchOrdersIdMutationRequest, PatchOrdersIdMutationResponse, PatchOrdersIdPathParams, PatchOrdersId400 } from '../entities/PatchOrdersId'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const patchOrdersIdMutationKey = () => [{ url: '/orders/{id}' }] as const

export type PatchOrdersIdMutationKey = ReturnType<typeof patchOrdersIdMutationKey>

/**
 * @description Обновление цены заказа
 * @summary Обновление цены заказа
 * {@link /orders/:id}
 */
async function patchOrdersId(
  id: PatchOrdersIdPathParams['id'],
  data: PatchOrdersIdMutationRequest,
  config: Partial<RequestConfig<PatchOrdersIdMutationRequest>> = {},
) {
  const res = await client<PatchOrdersIdMutationResponse, ResponseErrorConfig<PatchOrdersId400>, PatchOrdersIdMutationRequest>({
    method: 'PATCH',
    url: `/orders/${id}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Обновление цены заказа
 * @summary Обновление цены заказа
 * {@link /orders/:id}
 */
export function usePatchOrdersId(
  options: {
    mutation?: UseMutationOptions<
      PatchOrdersIdMutationResponse,
      ResponseErrorConfig<PatchOrdersId400>,
      { id: PatchOrdersIdPathParams['id']; data: PatchOrdersIdMutationRequest }
    >
    client?: Partial<RequestConfig<PatchOrdersIdMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? patchOrdersIdMutationKey()

  return useMutation<
    PatchOrdersIdMutationResponse,
    ResponseErrorConfig<PatchOrdersId400>,
    { id: PatchOrdersIdPathParams['id']; data: PatchOrdersIdMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return patchOrdersId(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}
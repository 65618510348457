import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  GetOrdersOrderIdCancelReasonsQueryResponse,
  GetOrdersOrderIdCancelReasonsPathParams,
  GetOrdersOrderIdCancelReasons400,
} from '../entities/GetOrdersOrderIdCancelReasons'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getOrdersOrderIdCancelReasonsQueryKey = (order_id: GetOrdersOrderIdCancelReasonsPathParams['order_id']) =>
  [{ url: '/orders/:order_id/cancel-reasons', params: { order_id: order_id } }] as const

export type GetOrdersOrderIdCancelReasonsQueryKey = ReturnType<typeof getOrdersOrderIdCancelReasonsQueryKey>

/**
 * @description Получение причин отмены заказа
 * @summary Получение причин отмены заказа
 * {@link /orders/:order_id/cancel-reasons}
 */
async function getOrdersOrderIdCancelReasons(order_id: GetOrdersOrderIdCancelReasonsPathParams['order_id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetOrdersOrderIdCancelReasonsQueryResponse, ResponseErrorConfig<GetOrdersOrderIdCancelReasons400>, unknown>({
    method: 'GET',
    url: `/orders/${order_id}/cancel-reasons`,
    ...config,
  })
  return res.data
}

export function getOrdersOrderIdCancelReasonsQueryOptions(order_id: GetOrdersOrderIdCancelReasonsPathParams['order_id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getOrdersOrderIdCancelReasonsQueryKey(order_id)
  return queryOptions<
    GetOrdersOrderIdCancelReasonsQueryResponse,
    ResponseErrorConfig<GetOrdersOrderIdCancelReasons400>,
    GetOrdersOrderIdCancelReasonsQueryResponse,
    typeof queryKey
  >({
    enabled: !!order_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getOrdersOrderIdCancelReasons(order_id, config)
    },
  })
}

/**
 * @description Получение причин отмены заказа
 * @summary Получение причин отмены заказа
 * {@link /orders/:order_id/cancel-reasons}
 */
export function useGetOrdersOrderIdCancelReasons<
  TData = GetOrdersOrderIdCancelReasonsQueryResponse,
  TQueryData = GetOrdersOrderIdCancelReasonsQueryResponse,
  TQueryKey extends QueryKey = GetOrdersOrderIdCancelReasonsQueryKey,
>(
  order_id: GetOrdersOrderIdCancelReasonsPathParams['order_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<GetOrdersOrderIdCancelReasonsQueryResponse, ResponseErrorConfig<GetOrdersOrderIdCancelReasons400>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getOrdersOrderIdCancelReasonsQueryKey(order_id)

  const query = useQuery({
    ...(getOrdersOrderIdCancelReasonsQueryOptions(order_id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetOrdersOrderIdCancelReasons400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}